import * as _ from 'lodash';
import api from '../interceptor/api';


export function getAirtelStores() {
    return api.request(`cms/maps/airtelshops?opco=${process.env.REACT_APP_OPCO}`)
}

export function searchNearByStores(lat,lng,distance){
    return api.request( `cms/maps/airtelshopsbycoordinates?opco=${process.env.REACT_APP_OPCO}&longitude=${lng}&latitude=${lat}&distanceInKilometers=${distance}`)
}

export function getActivePackage(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/fetch-sites-data-5g`);
}

export function getHelp() {
    return api.request(`cms/webpage/getFaqs_pCategory?opco=${process.env.REACT_APP_OPCO}`);
}


export function getFeedback(id, value) {
    return api.request(`cms/webpage/likeCount/?questionId=${id}&isLike=${value}&opco=${process.env.REACT_APP_OPCO}`)
}

export function getMetaData(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/meta-data-config`)
}

export function getFAQTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/faq-translation`)
}

export function getHelpTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/getHelp-translation`)
}

export function getFooterTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/footer-translation`)
}

export function getQuesCategoryTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/question-translation`)
}

export function getReachargeTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/recharge-translation`)
}

export function getSearchTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/search-translation`)
}


export function getStoreLocatorTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/storeLocator-translation`)
}


export function getNetworkTranslation(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/network-translation`)
}