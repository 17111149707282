import { useEffect, useState } from "react";
import './QuestionCategory.scss';
import { filterByUniqueData } from "../../helper/helper";
import { getQuesCategoryTranslation } from "../../service";

const QuestionCategory = ({ parentCategory, subCategory, categoryData, showQuesContent, selectedContent, staticPage, changeStage ,popularSearchQues}) => {

    const [uniqueCategories, setUniqueCategories] = useState([]);
    const [showQuestionContent, setShowQuestionContent] = useState(true);
   
    const [quesTranslation,setQuesTranslation]= useState({});

    useEffect(() => {
        fetchTranslation()
    }, [])

    const fetchTranslation = () => {
        getQuesCategoryTranslation().then(res => {
            if (res?.data?.packageHtml){
                setQuesTranslation(JSON.parse(res?.data?.packageHtml));
            }
        })
    }

    useEffect(() => {
        filterByCategory();
    }, [showQuesContent])


    const filterByCategory = () => {
        if (!!categoryData.length && showQuesContent) {
            setUniqueCategories(filterByUniqueData(categoryData, 'category'));
            setShowQuestionContent(true);
        } else {
            setShowQuestionContent(false);
        }
    }
    const showAnswer = (ele) => {
        changeStage(ele)
    }

    return (
        <>  <div className="category-wrapper">

            {showQuestionContent ? <div className="category-conatiner"><div className="container">
                {parentCategory && <span className="airtel-money">  {parentCategory}</span>}
                {subCategory && <span> / {subCategory} </span>}
                <div className="row">
                    <div className='col-sm-4 content-title'>{quesTranslation?.relatedWith}</div>
                    <div className="col-sm-8 category-card">
                        <div className="row">
                            {uniqueCategories?.map((ele, index) => (
                                <div className="col-sm-6 category" key={index} onClick={() => selectedContent(ele)}>{ele?.category}
                                    <span className="icon-outlined-link-right-arrow"></span>
                                </div>
                            ))}
                        </div>  </div>
                </div> </div>   </div> :
                <div className="category-conatiner">
                    <div className="container">
                    {parentCategory && <span className="airtel-money">  {parentCategory}</span>}
                        <div className="row">
                            <div className='col-sm-4 content-title'>{quesTranslation?.question}</div>
                            <div className="col-sm-8 card-questions">
                                {categoryData?.map((ele, index) => (
                                    <div className="col-sm-6 category" key={index} onClick={() => { showAnswer(ele) }}  >{ele?.question}  <span className="icon-outlined-link-right-arrow"></span></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {parentCategory !== 'Contact Details' && <div className="card-questions-container">
                <div className="container"> <div className="row">
                    {!!popularSearchQues.length && <h3 className="col-sm-4">{quesTranslation?.search}</h3>}
                    <div className="col-sm-8"> {!!popularSearchQues?.length &&
                        popularSearchQues.map((ele, index) => {
                            if (index < 5) {
                                return <div className="questions" key={index} onClick={() => showAnswer(ele)} >{ele?.question}
                                </div>
                            }
                        })}</div>
                </div> </div>  </div>}
        </div>
        </>
    )
}

export default QuestionCategory;