
const eventTracker = (window)?.eventTrackerInit();

export const eventTrackerInitialize = () => {
  // const eventBaseURL: string | undefined = "https://lite-uat.airtelkenya.com/api/b2c-backend/event";
  const channel = process.env.REACT_APP_CHANNEL;
  const xCountry = process.env.REACT_APP_X_COUNTRY || "";

  if (channel && xCountry && eventTracker) {
  eventTracker.initialize({
        eventBaseURL: "orp/bfe/api/portal-service",
        channel: channel,
        xCountry: xCountry || "",
      header: {
        "unmaskrequest": 'enable',
        'x-app-version': '1.0.0',
        'x-api-key': process.env.REACT_APP_MODE === "UAT" ?
          process.env.REACT_APP_BFE_API_KEY_UAT : process.env.REACT_APP_BFE_API_KEY_PROD,
        'x-service-id': process.env.REACT_APP_MODE === "UAT" ?
          process.env.REACT_APP_BFE_SERVICE_ID_UAT :process.env.REACT_APP_BFE_SERVICE_ID_PROD
      },
    });
  }
}

export const sendPageViewEvent = () => {
  eventTracker.send({ eventType: "visiter_trigerred", hitType: "pageview", page: window?.location?.pathname, title: window?.document?.title,  timeStamp: Date.now() });
}

export const sendEvent = ({ eventType, ...rest }) => {
  console.log('type pf ',process.env.REACT_APP_ENABLE_KPI == true)
  if(process.env.REACT_APP_ENABLE_KPI === "true" && eventTracker){
    const date = new Date();  
    eventTracker.send({
      eventType: eventType,
      timeStamp: date.toISOString(),
      ...rest
    });
  }
}


