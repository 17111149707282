export const FOUR_G_SERVICE='_4Gservice';
export const FOUR_G='4G coverage';
export const FIVE_G='5G coverage';
export const TWO_G='2G coverage';
export const THREE_G='3G coverage';
export const CONTACT_US='Contact Us';
export const AIRTEL_SHOPS='Airtel Shops';
export const DEALERS='Dealers';
export const SERVICE_EXP='Our Service Experience';
export const AIRTEL_MONEY_LOANS='Airtel Money Loans';
export const ROUTES={
    'CONTACT_US':'/contact-us',
   'AIRTEL_SHOPS' :'/store_finder/airtel_shop',
   'DEALERS':'/store_finder/dealers',
   'SERVICE_EXP':'/give_your_feedback',
   'AIRTEL_MONEY_LOANS':'/more-airtel-money'
}
export const FOOTER_URL=`/cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/website-footer-config`;
export const HEADER_URL=`/cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/website-headers-config`;
export const HOME_PAGE_URL=`/cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/website-homepage-config`;
export const FOOTER="footer";
export const HEADER="header";
export const HOME_PAGE="homePage";
export const GET="get";
export const cdnImage=`https://cdn-webportal.airtelstream.net/website/${process.env.REACT_APP_COUNTRY}/`;


